<template>
  <base-layout>
    <VoucherAddEdit
      :es-id="ae.esId"
      :primary-key="ae.primaryKey"
      :form-data="ae.formData"
      :is-editing="ae.isEditing"
      :fleet-currency="ae.fleetCurrency"
      :currency-symbol="ae.currencySymbol"
      :fleetList="fleetList"
      :currencies="currencies"
      @save="
        () => {
          $edgeStack.shouldNotConfirm(ae.esId)
          $store.dispatch('fsTable/fetchData')
        }
      "
      @close="$edgeStack.shouldNotConfirm(ae.esId)"
    />

    <NotifyRider
      :esId="details.esId"
      :busy="details.busy"
      :riderData="rider"
      :rawData="details.rawData"
      :fleets="fleets"
      :primaryKey="details.primaryKey"
      :fleetList="fleetList"
      :currencies="currencies"
      @edgestack="
        onEdgeStack({ esId: addEdit.esId, primaryKey: $event.taskId })
      "
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.offers.marketplace.headline')"
            @plus="onOpenES"
          />

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="summary-card-container">
          <SummaryCard
            v-for="summary in getSummaries"
            :key="`SummaryCard-` + summary.title"
            :title="summary.title"
            :value="summary.value"
            :variant="summary.variant"
            :icon="summary.variant"
          />
        </div>
      </div>

      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{
                    name: 'MarketingCampaignDetailsIndex',
                    params: { id: item.id },
                  }"
                />

                <FSTableRowItem :text="item.title" />

                <FSTableRowItem :text="item.description" />

                <FSTableRowItem>
                  <div
                    class="flex items-center gap-2 flex-wrap"
                    v-if="
                      item.assigned_fleets && item.assigned_fleets.length > 0
                    "
                  >
                    <RouterLink
                      class="text-blue-600"
                      v-for="(fleet, key) in item.assigned_fleets"
                      :key="key"
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: fleet.id },
                      }"
                      target="_blank"
                      >{{ fleet.name }}</RouterLink
                    >
                  </div>
                </FSTableRowItem>

                <!-- <FSTableRowItem>
              <x-status
                :text="item.is_active ? 'Active' : 'Inactive'"
                :variant="item.is_active ? 'green' : 'orange'"
              />
            </FSTableRowItem> -->

                <FSTableRowItem>
                  {{ item.expires_at | friendlyDateTime }}
                  <p>
                    (<TimeAgo
                      :title="item.expires_at"
                      :datetime="item.expires_at"
                      :auto-update="60"
                    />)
                  </p>
                </FSTableRowItem>

                <FSTableRowItem>
                  {{
                    getFormattedDateTime(
                      item.updated_at,
                      'D MMM YYYY, h:mm:ss a'
                    )
                  }}
                  <p>
                    (<TimeAgo
                      :title="item.updated_at"
                      :datetime="item.updated_at"
                      :auto-update="60"
                    />)
                  </p>
                </FSTableRowItem>
                <FSTableRowItem>
                  <x-status
                    :text="item.status"
                    :variant="getItemVariant(item.status)"
                  />
                </FSTableRowItem>

                <FSTableRowItem>
                  <div
                    class="flex items-center gap-1"
                    v-if="item.status !== 'Expired'"
                  >
                    <OtoEditIcon @click="onOpenES(item.id)" />
                    <!-- <MoreActions
                  :data="item"
                  @open="
                    onEdgeStack({
                      esId: details.esId,
                      primaryKey: item.id,
                      rawData: item,
                    })
                  "
                /> -->
                    <button
                      v-tooltip.top="'Notify Riders'"
                      @click="
                        onEdgeStack({
                          esId: details.esId,
                          primaryKey: item.id,
                          rawData: item,
                        })
                      "
                      class="
                flex
                items-center
                justify-center
                px-2
                py-0
                font-medium
                border
                rounded-full
                shadow-sm                
                h-35px
                text-14px text-oWhite
                focus:border-border-oWhite                
                focus:outline-none
                
              "
                      :class="[
                        item.status === 'Active'
                          ? 'bg-blue-600 hover:bg-blue-700'
                          : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed',
                      ]"
                    >
                      <div class="flex items-center ">
                        <i class="mr-2 fas fa-paper-plane"></i>
                      </div>
                    </button>
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.title"
                  :to="{
                    name: 'MarketingCampaignDetailsIndex',
                    params: { id: item.id },
                  }"
                />
                <FSTableRowItem>
                  <div
                    class="flex items-center gap-2 flex-wrap"
                    v-if="
                      item.assigned_fleets && item.assigned_fleets.length > 0
                    "
                  >
                    <RouterLink
                      class="text-blue-600"
                      v-for="(fleet, key) in item.assigned_fleets"
                      :key="key"
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: fleet.id },
                      }"
                      target="_blank"
                      >{{ fleet.name }}</RouterLink
                    >
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <x-status
                    :text="item.status"
                    :variant="getItemVariant(item.status)"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.fleetManagement.table.columns.location')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.fleetManagement.table.columns.location')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.description || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.offers.marketplace.table.columns.expireDate'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.expires_at | friendlyDateTime }}
                      <p>
                        (<TimeAgo
                          :title="item.expires_at"
                          :datetime="item.expires_at"
                          :auto-update="60"
                        />)
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.offers.marketplace.table.columns.lastUpdated'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.updated_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                      <p>
                        (<TimeAgo
                          :title="item.updated_at"
                          :datetime="item.updated_at"
                          :auto-update="60"
                        />)
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.offers.marketplace.table.columns.actions'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div
                        class="flex items-center gap-1"
                        v-if="item.status !== 'Expired'"
                      >
                        <OtoEditIcon @click="onOpenES(item.id)" />

                        <button
                          v-tooltip.top="'Notify Riders'"
                          @click="
                            onEdgeStack({
                              esId: details.esId,
                              primaryKey: item.id,
                              rawData: item,
                            })
                          "
                          class="
                flex
                items-center
                justify-center
                px-2
                py-0
                font-medium
                border
                rounded-full
                shadow-sm                
                h-35px
                text-14px text-oWhite
                focus:border-border-oWhite                
                focus:outline-none
                
              "
                          :class="[
                            item.status === 'Active'
                              ? 'bg-blue-600 hover:bg-blue-700'
                              : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed',
                          ]"
                        >
                          <div class="flex items-center ">
                            <i class="mr-2 fas fa-paper-plane"></i>
                          </div>
                        </button>
                      </div>
                      <div v-else>--</div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import { MarketingCampaignConfig } from '@/config/MarketingCampaignConfig'
import { useEdgeStack } from '@/plugins/edgeStack'
import { formatSummaries } from './helpers'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import XStatus from '@/components/badge/XStatus'
import SummaryCard from '@/components/cards/SummaryCard'
import ContentSection from '@/components/layout/ContentSection'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
// import MoreActions from '@/composites/voucher-management/MoreActions.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import VoucherAddEdit from './VoucherAddEdit'
import NotifyRider from './NotifyRider'

import TitlePlus from '@/components/ui/TitlePlus.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon.vue'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'MarketingIndex',
  components: {
    BaseLayout,
    ContentSection,
    VoucherAddEdit,
    NotifyRider,
    SummaryCard,
    XStatus,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    TitlePlus,
    FSTableFilter,
    // MoreActions,
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total_active_voucher: 0,
          total_inactive_voucher: 0,
          total_credit_voucher: 0,
          total_trip_voucher: 0,
        },
      },
      qso: { append: '', prepend: '?' },
      fstId: 'marketingIndex',
      filterOptions: [],
      indexDataEndpoint: MarketingCampaignConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.offers.marketplace.table.columns.campaignID'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.offers.marketplace.table.columns.title'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.table.columns.description'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t('components.offers.marketplace.table.columns.fleets'),
          width: '20%',
          sort: null,
        },
        // { text: 'Status', width: '10%', sort: null },
        {
          text: this.$t(
            'components.offers.marketplace.table.columns.expireDate'
          ),
          width: '15%',
          sort: 'expires_at',
        },
        {
          text: this.$t(
            'components.offers.marketplace.table.columns.lastUpdated'
          ),
          width: '15%',
          sort: 'updated_at',
        },
        {
          text: this.$t('components.offers.marketplace.table.columns.status'),
          width: '15%',
          sort: 'status',
        },
        {
          text: this.$t('components.offers.marketplace.table.columns.actions'),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.offers.marketplace.table.columns.title'),
          width: '40%',
          sort: null,
        },
        {
          text: this.$t('components.offers.marketplace.table.columns.fleets'),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t('components.offers.marketplace.table.columns.status'),
          width: '20%',
          sort: 'status',
        },
      ],
      // add-edit
      ae: {
        esId: 'voucherAddEdit',
        primaryKey: 0,
        isEditing: false,
        formData: {},
        fleetCurrency: '',
        currencySymbol: '$',
      },
      details: {
        esId: 'notifyRider',
        busy: false,
        rawData: null,
        vehicleOptions: null,
        primaryKey: null,
      },
      rider: {
        assigned_fleets: [],
        invitation_type: false,
        rider: '',
        selected_riders: [],
      },
      fleets: [],
      fleetList: [],
      currencies: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async onEdgeStack({
      esId = this.details.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
      rawData = null,
    } = {}) {
      if (['Expired', 'Inactive'].includes(rawData.status)) {
        if (rawData.status === 'Expired') {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Expired Voucher!',
              text: "You can't notify riders for expired voucher.",
            },
            5000
          )
        }
        if (rawData.status === 'Inactive') {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Inactive Voucher!',
              text:
                "You can't notify riders for inactive voucher. Please activate the voucher first.",
            },
            5000
          )
        }
        return
      } else {
        const edgeStack = useEdgeStack()
        this.rider.rider = ''
        this.rider.invitation_type = false
        this.rider.assigned_fleets = []
        this.rider.selected_riders = []

        // open the edgestack first, load data asynchronally if primary key is provided
        if (esAction === 'toggle') {
          edgeStack.toggle(esId)
        } else if (esAction === 'open') {
          edgeStack.open(esId)
        } else if (esAction === 'close') {
          edgeStack.close(esId)
        } else {
          throw new Error(`Unknown esAction: ${esAction}`)
        }

        // immidiately make edgestack busy & fetch & provide data if primary key is provided
        if (primaryKey && esId === this.details.esId) {
          // view mode
          this.details.busy = true
          this.details.primaryKey = primaryKey
          this.details.rawData = rawData
          this.getFleetOptions()
        }
      }
    },
    getFleetOptions() {
      let fleets = this.details?.rawData?.assigned_fleets.map(function(row) {
        return {
          text: row.name,
          value: row.id,
        }
      })
      this.fleets = fleets
    },
    onApplyFilterDateRange,
    async generateFilterOptions() {
      const filterableFleets = []
      await this.$http.get('/dashboard/fleets/').then(({ data }) => {
        if (data.data.length) {
          data.data.forEach((fleet) => {
            filterableFleets.push({
              text: fleet.name,
              value: fleet.id,
              symbol: fleet?.country?.currency_symbol,
            })
          })
        }
      })

      this.filterOptions = [
        {
          key: 'fleet_id',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        },
        // {
        //   key: 'status',
        //   type: 'checkbox',
        //   input: [
        //     { text: 'Active', value: 'Active' },
        //     { text: 'Inactive', value: 'Inactive' },
        //     { text: 'Expired', value: 'Expired' },
        //   ],
        //   title: 'Status',
        // },
      ]
    },
    async onOpenES(primaryKey = null) {
      this.ae.currencySymbol = this.currencySymbol

      if (primaryKey) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )

        // fetch data
        await this.$http
          .get(MarketingCampaignConfig.api.detail(primaryKey))
          .then((res) => {
            this.ae.primaryKey = res.data.id
            this.ae.isEditing = true

            // set fleet currency
            let fleet_id = res.data.assigned_fleets[0]?.id || ''

            let fleet = this.fleetList.find((row) => row.id === fleet_id)
            if (fleet) {
              this.ae.fleetCurrency = fleet?.country?.currency_symbol || ''
            }

            let data = res.data
            // map asigned fleets id
            data.assigned_fleets = res.data.assigned_fleets.map((x) => x.id)

            this.ae.formData = {
              is_active: data.is_active,
              title: data.title,
              description: data.description,
              expires_at: data.expires_at,
              push_options: data.push_options,
              assigned_fleets: data.assigned_fleets,
              invitation_type: false,
              selected_riders: [],
              rider: '',
            }

            // console.log({ da: res.data })
            // console.log({ fd: this.ae.formData })

            this.$edgeStack.open(this.ae.esId)
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        this.ae.isEditing = false
        this.ae.fleetCurrency = ''
        this.ae.primaryKey = 0
        this.ae.formData = {}
        this.$edgeStack.open(this.ae.esId)
      }
    },
    getPromoTypeChoice(choice) {
      const promoTypeChoice = {
        FR: 'Free Ride',
        FD: 'Flat Discount',
        RD: 'Rate Discount',
      }
      return choice in promoTypeChoice ? promoTypeChoice[choice] : '--'
    },

    async getFleets() {
      let { data } = await this.$http.get('/dashboard/fleets/?dropdown')
      if (data) {
        this.fleetList = data.data
        const currencyList = []
        data.data.forEach((fleet) => {
          const result = currencyList.find(
            (row) => fleet.country.code3 === row.text
          )
          if (!result) {
            currencyList.push({
              text: fleet.country.code3,
              value: fleet.country.currency_symbol,
            })
          }
        })
        this.currencies = currencyList
      }
    },
    getItemVariant(status) {
      if (status === 'Active') return 'green'
      if (status === 'Expired') return 'red'
      return 'gray'
    },
  },
  async created() {
    await this.generateFilterOptions()
    this.getFleets()
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
