<template>
  <EdgeStack :id="esId" class="relative" header-class-name="mb-4 mt-8 px-4">
    <loading :active.sync="reqBusy" :is-full-page="false" />

    <template #header>
      <div class="flex justify-between">
        <div class="items-center panel-title header-title">
          {{ getTitleText }}
        </div>
      </div>
    </template>

    <template #footer>
      <t-button type="submit" @click="submit()" class="ml-3">{{
        getActionButtonText
      }}</t-button>
    </template>

    <div class="px-6 pt-8">
      <CampaignCard
        ref="CampaignCard"
        :title="getCampaignTitle"
        :description="getCampaignDescription"
      />

      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(submitForm)"
          enctype="multipart/form-data"
        >
          <div class="mt-8 w-full">
            <text-input
              v-model="form.title"
              rules="required"
              :label="$t('components.offers.marketplace.addEdit.title.campaignTitle')"
              :placeholder="$t('components.offers.marketplace.addEdit.placeHolder.campaignTitle')"
            />
          </div>

          <div class="mt-2 w-full">
            <text-input
              input_type="textarea"
              v-model="form.description"
                :label="$t('components.offers.marketplace.addEdit.title.campaignDescription')"
             :placeholder="$t('components.offers.marketplace.addEdit.placeHolder.campaignDescription')"
            />
          </div>

          <!-- <div class="grid grid-cols-1 gap-4 mt-2">
            <VoucherAddEditStatusDropdown
              v-model="form.is_active"
              :label="'Status'"
              :options="voucherStatusOptions"
            />
          </div> -->

          <div class="mt-2 w-full">
            <text-input
              v-model="form.expires_at"
              rules="required"
              :label="$t('components.offers.marketplace.addEdit.title.campaignExpireDate')"
              :min="minDate"
              type="date"
            />
          </div>
          <div class="mt-2 w-full">
            <ValidationProvider
              name="Medium"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" :text="$t('components.offers.marketplace.addEdit.title.medium')" />
              <TRichSelect
                v-model="form.push_options"
                :options="mediumOptions"
                :close-on-select="false"
                :placeholder="$t('components.offers.marketplace.addEdit.placeHolder.medium')"
                :variant="{ danger: !!errors[0] }"
                multiple
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="mt-4">
            <ValidationProvider
              name="Fleet"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" :text="$t('components.offers.marketplace.addEdit.title.assignedFleet')" />
              <TRichSelect
                v-model="form.assigned_fleets"
                :options="getFleetOptions"
                :close-on-select="false"
                 :placeholder="$t('components.offers.marketplace.addEdit.placeHolder.assignedFleet')"
                :variant="{ danger: !!errors[0] }"
                multiple
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <!-- <div class="mt-4">
            <div class="flex justify-items-start items-center">
              <label class="font-bold mr-4">Invitation Type</label>
              <label class="cursor-pointer mr-4 flex items-center">
                <TRadioUnwrapped
                  v-model="form.invitation_type"
                  :value="false"
                  :name="`invitation_type`"
                />

                <span class="ml-3 font-medium text-14px text-oDark">All</span>
              </label>
              <label class="cursor-pointer flex items-center">
                <TRadioUnwrapped
                  v-model="form.invitation_type"
                  :value="true"
                  :name="`invitation_type`"
                />

                <span class="ml-3 font-medium text-14px text-oDark">
                  Custom
                </span>
              </label>
            </div>
          </div> -->

          <!-- <div class="mt-4" v-if="form.invitation_type">
            <label class="font-bold mr-4">    {{ $t('components.offers.marketplace.addEdit.title.searchRiderOrID') }}</label>
            <AppInput
              v-model="form.rider"
              type="richselect"
              name="riders"
              rules=""
             :placeholder="$t('components.offers.marketplace.addEdit.placeHolder.searchRiderOrID')"
              text-attribute="text"
              :fetch-options="getRiderList"
              @input="getSelectedRider"
            />
          </div> -->
          <!-- <div v-if="form.invitation_type">
            <table :id="`riderTable`" class="fst">
              <thead class="fst-head">
                <tr class="fst-head-row">
                  <th class="fst-head-row-item">Rider Name</th>
                  <th class="fst-head-row-item">Phone</th>
                  <th class="fst-head-row-item">Fleet</th>
                  <th class="w-15"></th>
                </tr>
              </thead>
              <tbody class="fst-body" v-if="form.selected_riders.length > 0">
                <tr
                  class="fst-row"
                  v-for="(item, key) in form.selected_riders"
                  :key="key"
                >
                  <td class="fst-row-item">{{ item.name }}</td>
                  <td class="fst-row-item">{{ item.phone }}</td>
                  <td class="fst-row-item">{{ item.nearby_fleet }}</td>
                  <td>
                    <span
                      class="
                    action
                    transition-all
                    ease-linear
                    delay-500
                    w-5
                    h-5
                    items-center
                    rounded-full
                    bg-gray-800
                    text-white
                    font-bold
                    justify-center
                    p-1
                    cursor-pointer
                  "
                      @click="removeRider(key)"
                    >
                      <i class="fas fa-times"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->

          <button type="submit" ref="submitButton" class="hidden">
           {{ $t('components.stepNavigation.create') }}
          </button>
        </form>
      </ValidationObserver>
    </div>
  </EdgeStack>
</template>

<script>
import { RiderUserConfig } from '@/config/RiderUserConfig'
import { MarketingCampaignConfig } from '@/config/MarketingCampaignConfig'
import { deepCompareObjects } from '@/utils'
import { edgeStackEmitter } from '@/plugins/edgeStack'
import CampaignCard from '@/components/cards/CampaignCard'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import EdgeStack from '@/components/modals/EdgeStack'
// import VoucherAddEditStatusDropdown from './VoucherAddEditStatusDropdown'

import { voucherStatusOptions, voucherTypes } from './helpers'

export default {
  name: 'VoucherAddEdit',
  props: {
    esId: {
      type: [String, Number],
      required: false,
      default: 'VoucherAddEdit',
    },
    primaryKey: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    formData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencySymbol: {
      type: [String, Number],
      required: false,
      default: '$',
    },
    fleetCurrency: {
      type: [String, Number],
      required: false,
      default: '',
    },
    fleetList: {
      type: Array,
      default: () => [],
    },
    currencies: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    EdgeStack,
    CampaignCard,
    TextInput,
    InputLabel,
    // VoucherAddEditStatusDropdown,
  },
  data() {
    return {
      mediumOptions: [
        {
          text: 'SMS',
          value: 'PO_SMS',
        },
        {
          text: 'Email',
          value: 'PO_EMAIL',
        },
        {
          text: 'Push',
          value: 'PO_PUSH',
        },
      ],
      reqBusy: false,
      form: {
        is_active: true,
        title: '',
        description: '',
        expires_at: '',
        push_options: [],
        assigned_fleets: [],
        invitation_type: true,
        selected_riders: [],
        rider: '',
      },
      voucherStatusOptions,
      voucherTypes,
      // fleet list is the raw data from server
      // based on fleet currency getFleetOptions() is computed
      fleetCurrencyProxy: this.fleetCurrency,
      dateFormat: {
        format: 'DD MMM YY',
      },
      minDate: '',
      currencyList: [],
      riderList: [],
    }
  },
  computed: {
    getTitleText() {
      return (this.isEditing ? 
          this.$t('components.offers.marketplace.addEdit.headline.update')
        : this.$t('components.offers.marketplace.addEdit.headline.add')) 
    },
    getActionButtonText: function() {
      return this.isEditing ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.create')
    },
    getVoucherType() {
      return this.form?.voucher_type
    },
    getCampaignTitle() {
      let title = this.form?.title
      if (!title || title.length === 0) return this.$t('components.offers.marketplace.addEdit.card.title.campaignTitle')
      return title
    },
    getCampaignDescription() {
      let description = this.form?.description
      if (!description || description.length === 0) {
        return this.$t('components.offers.marketplace.addEdit.card.title.campaignDescription')
      }
      return description
    },
    getLocation() {
      let fleets = this.form?.assigned_fleets
      let locations = []
      if (fleets && Array.isArray(fleets)) {
        fleets.forEach((fleet_id) => {
          let fleet = this.fleetList.find((row) => row.id === fleet_id)
          if (fleet) {
            locations.push(fleet.name)
          }
        })
      }
      return locations.join(', ')
    },
    getExpiresAt() {
      let expires_at = this.form?.expires_at
      if (expires_at && this.$dayjs().isValid(expires_at)) {
        return this.$UTCAwareTime(expires_at, this.dateFormat)
      }
      return ''
    },
    getVoucherReward() {
      const amount = this.form?.reward_amount || 0

      return this.getVoucherType === 'RV_CREDIT'
        ? `${this.fleetCurrencyProxy}${amount}`
        : this.getVoucherType === 'RV_TRIP'
        ? `${amount} min`
        : ''
    },
    getFleetOptions() {
      const fleetListFiltered = this.fleetList

      return fleetListFiltered.map((fleet) => ({
        text: `${fleet.name} (${fleet?.country?.code3})`,
        value: fleet.id,
      }))
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (Object.keys(data).length > 1) {
          this.form = { ...data, invitation_type: true }
          this.form.expires_at = this.$UTCAwareTime(data.expires_at, {
            format: 'YYYY-MM-DD',
          })
        } else {
          console.log('reset')
          this.resetForm()
          this.$edgeStack.shouldNotConfirm(this.esId)
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (
          deepCompareObjects(this.formData, updatedFormData) ||
          deepCompareObjects(updatedFormData, this.getFormModel())
        ) {
          return
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }
      },
    },
    fleetCurrency(updated) {
      this.fleetCurrencyProxy = updated
    },
    // reset selected fleets on changing fleet currency
    fleetCurrencyProxy(updated) {
      if (this.isEditing && updated === this.fleetCurrency) return

      if (updated) this.form.assigned_fleets = []
    },
  },
  async created() {
    this.minDatecalculation()
  },
  mounted() {
    edgeStackEmitter.on(
      this.$edgeStack.getEventName('close', this.esId),
      () => {
        this.$edgeStack.shouldNotConfirm(this.esId)
      }
    )
  },
  methods: {
    minDatecalculation() {
      this.minDate = this.$dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    },
    getSelectedRider(id) {
      let rider = this.riderList.find((row) => row.value === id)
      if (rider) {
        this.form.selected_riders.unshift(rider)
      }
    },
    removeRider(key) {
      this.form?.selected_riders.splice(key, 1)
    },
    async getRiderList(query) {
      let fleetQuery = ''

      if (this.form.assigned_fleets && this.form.assigned_fleets.length) {
        fleetQuery = `&${this.form.assigned_fleets
          .map((item) => `fleets=${item}`)
          .join('&')}`
      }

      const { data } = await this.$http.get(
        `${RiderUserConfig.api.index}?search=${query}${fleetQuery}`
      )
      let list = []
      if (data?.data.length > 0) {
        data.data.forEach(function(row) {
          list.push({
            name: row.full_name,
            text: `${row.full_name} (${row.phone_number})`,
            value: row.id,
            phone: row.phone_number,
            nearby_fleet: row?.nearby_fleet?.name,
          })
        })
      }
      this.riderList = list

      return { results: this.riderList }
    },
    onDirty() {
      this.$edgeStack.shouldConfirm(this.esId)
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async submitForm() {
      let formProxy = {
        title: this.form.title,
        description: this.form.description || '',
        is_active: this.form.is_active,
        expires_at: this.form.expires_at + 'T00:00:00',
        assigned_fleets: this.form.assigned_fleets,
        push_options: this.form.push_options,
        invitation_type: this.form.invitation_type,
        riders: this.form.selected_riders.map((item) => item.value),
      }

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? MarketingCampaignConfig.api.update(this.primaryKey)
        : MarketingCampaignConfig.api.create

      const formData = new FormData()
      // todo: make a form data converter function
      for (const key in formProxy) {
        if (Array.isArray(formProxy[key])) {
          formProxy[key].forEach((el) => {
            formData.append(key, el)
          })
          continue
        }
        formData.append(key, formProxy[key])
      }

      const message = this.isEditing
        ? 'Campaign updated successfully'
        : 'Campaign added successfully'

      this.reqBusy = true
      try {
        const res = await this.$http({ method, url, data: formData })

        // emit save event
        this.$emit('save', res.data)

        // Close ES
        this.$edgeStack.close(this.esId)
        // Reset the form
        this.$edgeStack.emitter.on(
          this.$edgeStack.getEventName('closed', this.esId),
          () => {
            this.resetForm()
            // this.$edgeStack.shouldNotConfirm(this.esId)
          }
        )

        this.$edgeStack.shouldNotConfirm(this.esId)

        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Success',
          text: message,
        })
      } catch (err) {
        console.log({ err })

        this.$notify({
          group: 'generic',
          type: 'error',
          title: 'Error',
          text: err.response.data.message,
        })
      } finally {
        this.reqBusy = false
        this.$edgeStack.shouldNotConfirm(this.esId)
      }
    },
    getFormModel() {
      return {
        is_active: true,
        title: '',
        description: '',
        expires_at: '',
        push_options: [],
        assigned_fleets: [],
        invitation_type: true,
        selected_riders: [],
        rider: '',
      }
    },
    resetForm() {
      this.form = { ...this.getFormModel() }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}

.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
}

.list-item {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 10px;
  padding: 10px;
  font-size: 14px;

  & .title {
    grid-column: span 1 / span 1;
    font-weight: 700;
    color: #2e2e39;
  }

  & .value {
    grid-column: span 2 / span 2;
    color: #6d6d75;
  }
}
</style>

<style lang="scss" scoped>
@import './$fs-table.scss';
</style>
